import { CSSProperties, ForwardedRef, forwardRef } from 'react';
import NextLink from 'next/link';
import { Box, BoxProps, Link } from '@mui/material';
import { isMobile } from 'react-device-detect';

interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  showFullLogo?: boolean;
  showTextOnly?: boolean;
  textSx?: CSSProperties;
  link?: string;
}

const Logo = forwardRef((props: LogoProps, ref: ForwardedRef<any>) => {
  const { disabledLink = false, showFullLogo = true, showTextOnly = false, link, sx, textSx, ...other } = props;

  const MyPortraitStudio = () => (
    <Box className="text-container">
      {'MyPortrait Studio'.split('').map((char, index) => (
        <span className={`glowing-text ${char === ' ' ? 'space' : ''}`} style={{ animationDelay: `${index * 0.5}s` }} key={index}>
          {char === ' ' ? '\u00A0' : char}
        </span>
      ))}
    </Box>
  );

  const logo = (
    <Box display="flex" alignItems="center">
      {!showTextOnly && (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: 24,
            height: 24,
            display: 'inline-flex',
            marginLeft: '8px',
            ...sx,
          }}
          {...other}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.6 54.24" height="24" width="24" className="glow-effect">
            <path
              d="M28.33,0C12.71,0,0,10.46,0,23.33c0,2.94,.67,5.83,1.98,8.57,.57,1.14,1.17,2.15,1.82,3.05l1.78,2.22,.23,.24,13.52,16.64c.28,.34,.83,.21,.92-.22l3.24-14.36c.08-.35-.15-.69-.5-.76-2.28-.46-4.44-1.2-6.43-2.23-1.83-.95-3.42-2.1-4.75-3.42-2.88-2.8-4.41-6.18-4.41-9.74,0-8.77,9.38-15.91,20.92-15.91s20.88,7.14,20.88,15.91-8.44,15.28-19.38,15.87c-.28,.02-.52,.21-.58,.49l-1.44,6.37c-.07,.3,.16,.6,.47,.6h0c8.27,0,15.99-2.9,21.43-8.08,4.45-4.24,6.9-9.65,6.9-15.24C56.63,10.46,43.93,0,28.33,0Z"
              fill="#ffffff"
            ></path>
            <ellipse cx="18.56" cy="23.24" rx="4.44" ry="5.85" fill="#ffffff"></ellipse>
            <ellipse cx="38.07" cy="23.24" rx="4.44" ry="5.85" fill="#ffffff"></ellipse>
          </svg>
        </Box>
      )}
      {showFullLogo && (
        <Box ml={showTextOnly ? 0 : 1.5} sx={{ lineHeight: '24px', fontSize: '1.25em', display: 'flex', alignItems: 'center', ...textSx }}>
          <MyPortraitStudio />
        </Box>
      )}
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={NextLink} href={link || '/'} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

export default Logo;
