import PropTypes from 'prop-types';
// next
import NextLink from 'next/link';
// @mui
import { Link, ListItemText, ListItemIcon } from '@mui/material';
// components
import Iconify from '../../../../components/iconify';
//
import { ListItem } from './styles';
import useLocales from '@/locales/useLocales';

// ----------------------------------------------------------------------

type NavItemProps = {
  open?: boolean;
  active?: boolean;
  item: {
    title: string;
    path: string;
    icon?: JSX.Element | undefined; // Allow undefined or JSX.Element
    children?: Array<any>;
  };
  isExternalLink?: boolean;
  onClick?: VoidFunction;
};

export default function NavItem({ item, open, active, isExternalLink, ...other }: NavItemProps) {
  const { title, path, icon, children } = item;
  const { translate } = useLocales();

  const renderContent = (
    <ListItem active={active} {...other}>
      <ListItemIcon>{icon ? icon : null}</ListItemIcon>
      <ListItemText disableTypography primary={translate(title)} />

      {!!children && <Iconify width={16} icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'} sx={{ ml: 1 }} />}
    </ListItem>
  );

  // ExternalLink
  if (isExternalLink) {
    return (
      <Link href={path} target="_blank" rel="noopener" underline="none">
        {renderContent}
      </Link>
    );
  }

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link component={NextLink} href={path} underline="none">
      {renderContent}
    </Link>
  );
}
