// next
import NextLink from 'next/link';
import { useRouter } from 'next/router';
// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography, IconButton } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// _mock
import { _socials } from '../../_mock/arrays';
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
import { Tooltip } from '@mui/material';
import useLocales from '@/locales/useLocales';

export default function Footer() {
  const { pathname } = useRouter();

  const { translate } = useLocales();

  const LINKS = [
    {
      headline: 'MyPortrait',
      children: [
        // { name: translate('footer.about'), href: PATH_PAGE.about },
        { name: translate('footer.contactUs'), href: PATH_PAGE.contact },
        { name: translate('footer.faq'), href: PATH_PAGE.faqs },
      ],
    },
    {
      headline: translate('footer.legal'),
      children: [
        { name: translate('footer.terms'), href: PATH_PAGE.terms },
        { name: translate('footer.privacy'), href: PATH_PAGE.privacy },
        { name: translate('footer.imprint'), href: PATH_PAGE.imprint },
      ],
    },
    {
      headline: translate('footer.contact'),
      children: [
        {
          name: String.fromCharCode(
            105,
            110,
            102,
            111,
            64,
            109,
            121,
            112,
            111,
            114,
            116,
            114,
            97,
            105,
            116,
            46,
            115,
            116,
            117,
            100,
            105,
            111
          ),
          href: PATH_PAGE.contact,
        },
        {
          name: 'GC JEEDOO SL <br /> DOMICILIO: Avda Touroperador Finnmatkat, Num. 9 <br /> SOCIAL: Puerta 21 <br /> Aptos Sunny Golf - Maspalomas <br /> 35100 San Bartolome de Tirajana <br /> Las Palmas de Gran Canaria',
          href: PATH_PAGE.contact,
        },
      ],
    },
  ];

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography variant="caption" component="div">
          © All rights reserved
        </Typography>
      </Container>
    </Box>
  );
  const currentYear = new Date().getFullYear();
  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item xs={12} md={4}>
            <Logo showTextOnly={true} />
            <Typography
              variant="body1"
              sx={{
                pr: { md: 6 },
                mt: 1.5,
                textAlignLast: 'left',
                textJustify: 'inter-word',
                textAlign: 'justify',
              }}
            >
              {translate('footer.description')}
            </Typography>

            <Stack
              spacing={1}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 0.5,
                ml: -1,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <Tooltip key={social.name} title="Not ready yet">
                  <IconButton>
                    <Iconify icon={social.icon} />
                  </IconButton>
                </Tooltip>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={3} justifyContent="space-between" direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list, index) => (
                <Stack key={list.headline.toString()} spacing={0} alignItems={{ xs: 'center', md: 'flex-start' }}>
                  <Typography component="div" sx={{ fontSize: '20px', mb: 0.5 }}>
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name.toString()}
                      component={NextLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                      dangerouslySetInnerHTML={{ __html: link.name }}
                    />
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          component="div"
          sx={{
            my: 3,
          }}
        >
          Copyright 2023 - {currentYear} © GC JEEDOO SL. All rights reserved. Registered trademarks belong to their respective owners.
        </Typography>
      </Container>
    </Box>
  );

  return mainFooter;
  // return isHome ? simpleFooter : mainFooter;
}
