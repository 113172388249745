import React from 'react';
import { Popover, PopoverProps, useTheme } from '@mui/material';
import { getPosition } from './getPosition';
import { StyledArrow } from './styles';

// ----------------------------------------------------------------------

interface MenuPopoverProps extends Omit<PopoverProps, 'open' | 'children'> {
  sx?: object;
  open: boolean | HTMLElement | null; // Assuming that 'open' can be a boolean or an HTMLElement
  children?: React.ReactNode;
  disabledArrow?: boolean;
  arrow?:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right'
    | 'left-top'
    | 'left-center'
    | 'left-bottom'
    | 'right-top'
    | 'right-center'
  | 'right-bottom';
}

export default function MenuPopover({
  open,
  children,
  arrow = 'top-right',
  disabledArrow,
  sx,
  ...other
}: MenuPopoverProps) {
  const { style, anchorOrigin, transformOrigin } = getPosition(arrow);
  const theme = useTheme();

  return (
    <Popover
      open={Boolean(open)}
      anchorEl={open as HTMLElement}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      sx={{
        p: 1,
        width: 'auto',
        overflow: 'inherit',
        ...style,
        '& .MuiMenuItem-root': {
          px: 1,
          typography: 'body2',
          borderRadius: 0.75,
          '& svg': { mr: 2, width: 20, height: 20, flexShrink: 0 },
        },
        ...sx,
      }}
      {...other}
    >
      {!disabledArrow && <StyledArrow theme={theme} arrow={arrow} />}

      {children}
    </Popover>
  );
}