import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// next
import { useRouter } from 'next/router';
// @mui
import { List, Drawer, IconButton, Box, Divider } from '@mui/material';
// config
import { NAV } from '../../../../config-global';
// components
import Logo from '../../../../components/logo';
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
//
import NavList from './NavList';
import useResponsive from '@/hooks/useResponsive';
import { useAtomValue } from 'jotai';
import { safeAreaInsetTopAtom } from '@/src/atoms/_app.states';
import LanguagePopover from '@/layouts/dashboard/header/LanguagePopover';
import { isMobile } from 'react-device-detect';
import useLocales from '@/locales/useLocales';
import { PATH_PAGE } from '@/routes/paths';
import NavDocs from '@/layouts/dashboard/nav/NavDocs';

// ----------------------------------------------------------------------

type NavMobileProps = {
  isOffset: boolean;
  data: Array<{
    title: string;
    path: string;
    children?: Array<any>;
  }>;
};

export default function NavMobile({ isOffset, data }: NavMobileProps) {
  const { pathname } = useRouter();

  const [open, setOpen] = useState(false);

  const isSmallScreen = useResponsive('down', 800);
  const safeAreaInsetTop = useAtomValue(safeAreaInsetTopAtom);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { translate } = useLocales();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          position: 'relative',
        }}
      >
        <Logo
          showTextOnly={true}
          sx={{
            my: 3,
          }}
          textSx={{ fontSize: isSmallScreen ? 20 : 24 }}
        />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LanguagePopover />
          <IconButton
            onClick={handleOpen}
            sx={{
              ...(isOffset && {
                color: 'text.primary',
              }),
            }}
          >
            <Iconify icon="eva:menu-2-fill" sx={{ width: 24, height: 24 }} />
          </IconButton>
        </Box>
      </Box>

      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE,
          },
        }}
      >
        <Scrollbar>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'fixed',
              top: 0,
              left: 0,
              width: NAV.W_BASE,
              height: '100%',
              paddingTop: `${safeAreaInsetTop}px`,
            }}
          >
            <Box sx={{ pl: 2.5, py: isSmallScreen ? 1.5 : 3 }} style={{}}>
              <Logo showTextOnly={true} sx={{ mx: 2.5, my: 3 }} textSx={{ fontSize: isSmallScreen ? 20 : 24 }} />
            </Box>

            {isMobile && <Divider sx={{ my: 3 }} />}
            <List component="nav" disablePadding>
              {data.map((link) => (
                <NavList key={link.title} item={link} />
              ))}
            </List>
            {isMobile && (
              <Box sx={{}}>
                <Divider sx={{ my: 3 }} />
                <List component="nav" disablePadding>
                  <NavList
                    item={{
                      title: translate('footer.faq'),
                      path: PATH_PAGE.faqs,
                      icon: <Iconify icon="eva:question-mark-circle-outline" />,
                    }}
                  />
                  <NavList
                    item={{ title: translate('footer.terms'), path: PATH_PAGE.terms, icon: <Iconify icon="eva:file-text-outline" /> }}
                  />
                  <NavList
                    item={{ title: translate('footer.privacy'), path: PATH_PAGE.privacy, icon: <Iconify icon="eva:shield-outline" /> }}
                  />
                  <NavList
                    item={{ title: translate('footer.imprint'), path: PATH_PAGE.imprint, icon: <Iconify icon="eva:info-outline" /> }}
                  />
                </List>
                <Box sx={{ flexGrow: 1 }} />

                <NavDocs />
              </Box>
            )}
          </Box>
        </Scrollbar>
      </Drawer>
    </>
  );
}
