// @mui
import { Stack, Button, Typography, Box } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// routes
import { PATH_PAGE } from '../../../routes/paths';
import Link from 'next/link';
import { useAuth } from '@/providers/AuthProvider';
import AppConfig from '@/config/config';
// ----------------------------------------------------------------------

export default function NavDocs() {
  const { user } = useAuth();
  const { translate } = useLocales();

  return (
    <Stack
      spacing={3}
      sx={{
        px: 3,
        pb: 5,
        mt: 10,
        width: 1,
        display: 'block',
        // textAlign: 'center',
      }}
    >
      {/* <Box component="img" src="/assets/illustrations/illustration_docs.svg" /> */}

      <Box pb={2}>
        <Typography gutterBottom variant="subtitle1">
          {`${translate('docs.hi')}, ${user?.firstName ? user.firstName : ''}`}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          {translate('docs.doYouNeedOurHelp')}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
          v.{AppConfig.version}
        </Typography>
      </Box>

      <Link href={PATH_PAGE.contact} shallow>
        <Button rel="noopener" variant="contained">
          {translate('buttons.contactUs')}
        </Button>
      </Link>
    </Stack>
  );
}
