import { Box, Stack } from '@mui/material';

import NavList from './NavList';
import LanguagePopover from '@/layouts/dashboard/header/LanguagePopover';
import Logo from '@/components/logo';

type NavDesktopProps = {
  isOffset: boolean;
  data: Array<{
    title: string;
    path: string;
    children?: Array<any>;
  }>;
};

export default function NavDesktop({ isOffset, data }: NavDesktopProps) {
  return (
    <>
      <Logo showTextOnly={true} />
      <Box sx={{ flexGrow: 1 }} />
      <Stack component="nav" direction="row" spacing={5} sx={{ height: 1, justifyContent: 'flex-end' }}>
        {data.map((link) => (
          <NavList key={link.title} item={link} isOffset={isOffset} />
        ))}
        <LanguagePopover />
      </Stack>
    </>
  );
}
