import React, { useState, useEffect, forwardRef, ForwardedRef, CSSProperties } from 'react';
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';
import { Box, BoxProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface ImageProps extends Omit<React.ComponentProps<typeof LazyLoadImage>, 'ref'> {
  disabledEffect?: boolean;
  effect?: string;
}

const buildSx = (additionalStyles?: SxProps<Theme>): SxProps<Theme> => ({
  lineHeight: 1,
  display: 'block',
  overflow: 'hidden',
  position: 'relative',
  transition: 'opacity .6s ease',
  '& .wrapper': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1,
    height: 1,
    backgroundSize: 'cover !important',
  },
  ...additionalStyles,
});

const Image = forwardRef((props: ImageProps, ref: ForwardedRef<HTMLSpanElement>) => {
  const { disabledEffect = false, effect = 'fade', preload, sx, ...other } = props;
  const [opacity, setOpacity] = useState(0);
  const [beforeLoad, setBeforeLoad] = useState(true);

  useEffect(() => {
    if (beforeLoad) {
      setTimeout(() => {
        setOpacity(0);
      }, 100);
    }
  }, [beforeLoad]);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }, []);

  const handleImageLoaded = () => {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  };

  const content = (
    <Box
      component={LazyLoadImage}
      wrapperClassName="wrapper"
      effect={disabledEffect ? undefined : effect}
      placeholderSrc={disabledEffect ? '/assets/transparent.png' : '/assets/placeholder.svg'}
      sx={{
        width: 1,
        height: 1,
        objectFit: 'cover',
        opacity: preload ? 0.5 : 1,
        ...(preload
          ? {
              boxShadow:
                'inset 0px 4px 6px -1px rgba(0, 0, 0, 0.2), inset 0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
            }
          : {}),
      }}
      afterLoad={handleImageLoaded}
      {...other}
    />
  );

  return (
    <Box ref={ref} component="span" sx={{ ...buildSx(sx), opacity }}>
      {content}
    </Box>
  );
});

export default Image;
