// @mui
import { styled, alpha } from '@mui/material/styles';
import { Paper, ListSubheader, ListItemButton, PaperProps } from '@mui/material';
// utils
import { bgBlur } from '../../../../utils/cssStyles';
import { ListItemButtonProps } from '@mui/material';

interface CustomListItemProps extends ListItemButtonProps {
  isOffset?: boolean;
  active?: boolean;
  open?: boolean;
  subItem?: boolean;
}

export const ListItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'open' && prop !== 'isOffset' && prop !== 'subItem',
})<CustomListItemProps>(({ active, open, isOffset, subItem, theme }) => {
  return {
    padding: 0,
    height: '100%',
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
      opacity: 0.48,
      backgroundColor: 'transparent',
    },
    // Sub item
    ...(subItem && {
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    }),
    // isOffset
    ...(isOffset && {
      color: theme.palette.text.primary,
    }),
    // Active
    ...(active && {
      color: theme.palette.primary.main,
    }),
    // Active sub item
    ...(active &&
      subItem && {
        color: theme.palette.text.primary,
      }),
    // Open
    ...(open && {
      opacity: 0.48,
    }),
  };
});

// ----------------------------------------------------------------------

export const StyledMenu = styled(Paper)<PaperProps>`
  ${({ theme }) => `
    ${bgBlur({
      opacity: 0.94,
      color: theme.palette.background.default,
    })}
    top: 72px;
    left: 0;
    right: 0;
    margin: auto;
    display: grid;
    position: fixed;
    align-items: flex-start;
    z-index: ${theme.zIndex.modal};
    padding: ${theme.spacing(5, 1, 1, 3)};
    box-shadow: ${theme.customShadows?.z24};
    max-width: ${theme.breakpoints.values.lg}px;
    grid-template-columns: repeat(12, 1fr);
    border-radius: ${Number(theme.shape.borderRadius) * 2}px;
    border: solid 1px ${alpha(theme.palette.grey[500], 0.16)};
  `}
`;

export const StyledSubheader = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.overline,
  padding: 0,
  fontSize: 11,
  color: theme.palette.text.primary,
}));
